import React from 'react'
import Seo from '../components/Seo'
import Grid from '../components/_layouts/Grid'
import GridItem from '../components/_layouts/GridItem'
import {
  Heading1,
  Heading2,
  Heading3,
  TextBody,
  TextBodyLarge,
  TextBodySmall,
} from '../components/TextStyles'
import Spacer from '../components/Spacer'
import Flow from '../components/Flow'
import AnimateSplitText from '../components/_animation/AnimateSplitText'
import { animation } from '../styles/vars/animation.style'

const IndexPage = () => (
  <>
    <Seo title="Style Guide" />
    <Grid>
      <GridItem tabletL={6} tabletLStart={2}>
        <Spacer axis="vertical" size={[120, 260]} />
        <Flow>
          <Heading1>
            <AnimateSplitText delay={animation.pageExitDuration}>
              Heading 1
            </AnimateSplitText>
          </Heading1>

          <Heading2>
            <AnimateSplitText delay={animation.pageExitDuration + 0.2}>
              Heading 2
            </AnimateSplitText>
          </Heading2>

          <Heading3>
            <AnimateSplitText delay={animation.pageExitDuration + 0.4}>
              Heading 3
            </AnimateSplitText>
          </Heading3>

          <TextBodyLarge>
            <AnimateSplitText delay={animation.pageExitDuration + 1}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
              error odio minima nemo dolor repellendus aliquam earum hic, ipsum
              magnam, vitae consequuntur, accusantium facere magni possimus
              fuga! Odio, a minima?
            </AnimateSplitText>
          </TextBodyLarge>

          <TextBody>
            <AnimateSplitText delay={animation.pageExitDuration + 1.2}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
              labore odit vitae odio voluptatem non reiciendis animi dolorum,
              cum minima corporis alias error assumenda. Dolorum repudiandae
              eius exercitationem. Iste, dolore.
            </AnimateSplitText>
          </TextBody>

          <TextBodySmall>
            <AnimateSplitText delay={animation.pageExitDuration + 1.4}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
              labore odit vitae odio voluptatem non reiciendis animi dolorum,
              cum minima corporis alias error assumenda. Dolorum repudiandae
              eius exercitationem. Iste, dolore.
            </AnimateSplitText>
          </TextBodySmall>
        </Flow>
        <Spacer axis="vertical" size={[120, 360]} />
      </GridItem>
    </Grid>
  </>
)

export default IndexPage
